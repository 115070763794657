import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade interval={5000} controls={false} indicators={false}>
      <Carousel.Item>
        <img src={require("../Images/carousel/first.jpg")} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={require("../Images/carousel/second.jpg")} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={require("../Images/carousel/third.jpg")} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={require("../Images/carousel/four.jpg")} alt="Fourth slide" />
      </Carousel.Item>
    </Carousel>   
  );
}

export default CarouselFade;