import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navbars(props) {
    return (
        <div>
            <nav id="desktop" className="navbar navbar-expand-sm fixed-top navbar-dark hidden-xs hidden-sm visible-lg visible-md" data-toggle="affix">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainnav" aria-controls="mainnav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="mainnav">
                    <ul className="navbar-nav navbar-center">
                        <li className="nav-item desktop-nav-item">
                            <a className="nav-link" href={"#"+props.data[0].Section}>{props.data[0].Title}</a>
                        </li>
                        <li className="nav-item desktop-nav-item">
                            <a className="nav-link" href={"#"+props.data[1].Section}>{props.data[1].Title}</a>
                        </li>
                        <div className="navbar-brand desktop-nav-item">
                            <h3 className="nav-brand-title">SKOUGAARD_PHOTOGRAPHY</h3>
                        </div>
                        <li className="nav-item desktop-nav-item">
                            <a className="nav-link" href={"#"+props.data[2].Section}>{props.data[2].Title}</a>
                        </li>
                        <li className="nav-item desktop-nav-item">
                            <a className="nav-link" href={"#"+props.data[3].Section}>{props.data[3].Title}</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <Navbar expand="sm" collapseOnSelect className="navbar-dark visible-xs visible-sm hidden-lg hidden-md nav-bg" fixed="top">
                <Container>
                    <Navbar.Brand className='navbar-brand mobile-nav-brand'>
                        <h3 className="nav-brand-title-mobile">SKOUGAARD_PHOTOGRAPHY</h3>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav ">
                    <Nav>
                        <Nav.Link className='mobile-nav-link' href={"#"+props.data[0].Section}>{props.data[0].Title}</Nav.Link>
                        <Nav.Link className='mobile-nav-link' href={"#"+props.data[1].Section}>{props.data[1].Title}</Nav.Link>
                        <Nav.Link className='mobile-nav-link' href={"#"+props.data[2].Section}>{props.data[2].Title}</Nav.Link>
                        <Nav.Link className='mobile-nav-link' href={"#"+props.data[3].Section}>{props.data[3].Title}</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
  }
  
  export default Navbars;