//import './App.css';
import './base.css';
import React from 'react'
import Thumbnail from './Components/Thumbnail';
import CarouselFade from './Components/Carousel';
import Navbars from './Components/Nav';
import Gallery from './Components/Gallery';
import Modal from 'react-bootstrap/Modal';
import json from './Configuration.json'


function App() {
  const [contactShow, setContactShow] = React.useState(false)
  const [thumb1Show, setThumb1Show] = React.useState(false)
  const [thumb2Show, setThumb2Show] = React.useState(false)
  const [thumb3Show, setThumb3Show] = React.useState(false)
  const [thumb4Show, setThumb4Show] = React.useState(false)
  const [thumb5Show, setThumb5Show] = React.useState(false)
  const [thumb6Show, setThumb6Show] = React.useState(false)
  return (
    <div>
      <Navbars data={json.Nav}/>
      <header>
      <CarouselFade/>
      <div className="header-content hidden-xs visible-lg visible-md visible-sm">
            <div className="header-content-inner">
                <h2>FOREVIG JERES MINDER</h2>
                <p>ØJEBLIKSBILLEDER FRA HVERDAGEN</p>
            </div>
        </div>
      </header>
      
      <div className='container'>
      <section id={json.Nav[0].Section}>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2 text-center">
              <h3>VELKOMMEN TIL</h3>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          </section>
        </div>
        <section id={json.Nav[1].Section}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb1Show(true)}>
              <Thumbnail data={json.Thumbnails[0]} className="col-lg-4 col-sm-6 img-wrap"/>
              <div className='img-content'>
                <div className='img-content-inner'>
                  <h1>{json.Thumbnails[0].Title}</h1>
                  <p>{json.Thumbnails[0].Subtitle}</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb2Show(true)}>
              <Thumbnail data={json.Thumbnails[1]} className="col-lg-4 col-sm-6 img-wrap"/>
              <div className='img-content'>
                <div className='img-content-inner'>
                  <h1>{json.Thumbnails[1].Title}</h1>
                  <p>{json.Thumbnails[1].Subtitle}</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb3Show(true)}>
              <Thumbnail data={json.Thumbnails[2]} className="col-lg-4 col-sm-6 img-wrap"/>
              <div className='img-content'>
                <div className='img-content-inner'>
                  <h1>{json.Thumbnails[2].Title}</h1>
                  <p>{json.Thumbnails[2].Subtitle}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="bg-container">
        <div className="row text-center">
          <div className="col-lg-offset-3 col-lg-3">
            <button type="button" className="btn txt-align-vert" onClick={() => setContactShow(true)}>Book tid nu</button>
          </div>
          <div className="col-lg-3">
            <p className='txt-align-vert'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
    <div className="container">
      <div className='row'>
        <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb4Show(true)}>
          <Thumbnail data={json.Thumbnails[3]} className="col-lg-4 col-sm-6 img-wrap"/>
          <div className='img-content'>
              <div className='img-content-inner'>
                <h1>{json.Thumbnails[3].Title}</h1>
                <p>{json.Thumbnails[3].Subtitle}</p>
              </div>
            </div>
        </div>
        <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb5Show(true)}>
          <Thumbnail data={json.Thumbnails[4]} className="col-lg-4 col-sm-6 img-wrap"/>
          <div className='img-content'>
              <div className='img-content-inner'>
                <h1>{json.Thumbnails[4].Title}</h1>
                <p>{json.Thumbnails[4].Subtitle}</p>
              </div>
            </div>
        </div>
        <div className='col-lg-4 col-sm-6 img-wrap' onClick={() => setThumb6Show(true)}>
          <Thumbnail data={json.Thumbnails[5]} className="col-lg-4 col-sm-6 img-wrap"/>
          <div className='img-content'>
              <div className='img-content-inner'>
                <h1>{json.Thumbnails[5].Title}</h1>
                <p>{json.Thumbnails[5].Subtitle}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
    </section>
    <div className="bg-container">
      <div className="row text-center">
      <div className="col-lg-offset-3 col-lg-6">
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
          </svg>
          <br/>
          <span className='font-italic'>Life is like a camera,<br/>
          focus on what's important</span>
        </p>
        <div className='line'></div>
      </div>
      </div>
    </div>
    <div className='container'>
      <section id={json.Nav[2].Section}>
      <div className="row">
            <div className="col-lg-8 col-lg-offset-2 text-center">
              <h3>Det tilbyder jeg</h3>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
      </section>
      <section id={json.Nav[3].Section}>
      <div className="row">
            <div className="col-lg-8 col-lg-offset-2 text-center">
            <h3>Lidt om mig</h3>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
      </section>
    </div>


    <Modal show={thumb1Show} onHide={() => setThumb1Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[0].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={thumb2Show} onHide={() => setThumb2Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[1].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={thumb3Show} onHide={() => setThumb3Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[2].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={thumb4Show} onHide={() => setThumb4Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[3].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={thumb5Show} onHide={() => setThumb5Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[4].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={thumb6Show} onHide={() => setThumb6Show(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{json.Thumbnails[5].Title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Gallery/>
        </Modal.Body>
    </Modal>
    <Modal show={contactShow} onHide={() => setContactShow(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Send en forespørgsel, så kontakter jeg dig hurtigst muligt.</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
          <form>
            <div className='row'>
              <div className='col-sm-6'>
              <label>
                Navn:
                <input type="text"/>
              </label>
              </div>
            </div>
            <div className='row'>
            <div className='col-sm-6'>
              <label>
                Email:
                <input type="text" />
              </label>
              </div>
              <div className='col-sm-6'>
              <label>
                Telefonnr.:
                <input type="text" />
              </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
              <label>
                Skriv lidt om dine ønsker til dato, tidspunkt og billeder:
                <textarea/>
              </label>
              </div>
            </div>
            <input type="submit" value="Send forespørgsel" />
          </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
